import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-privacy-policies',
    templateUrl: 'privacy-policies.component.html',
    styleUrls: ['privacy-policies.component.scss'],
})
export class PrivacyPoliciesComponent {
    isLoading = false;
    showPP = true;

    constructor(private route: ActivatedRoute, private router: Router) {}
    ngOnInit() {}

    onClose() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
