import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiServiceEss } from './api.service-ess';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { over } from 'stompjs';
import SockJS from 'sockjs-client';

@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    private stompClient: any;
    private stompHeaders: any;
    public connectionStatus$: Subject<boolean> = new Subject<boolean>();
    public isConnected: boolean = false;
    private reconnectAttempts = 0;
    private maxReconnectAttempts = 5;

    constructor(
        private readonly _authService: AuthenticationService,
        private readonly _apiAdmin: ApiService,
        private readonly _apiEss: ApiServiceEss
    ) {}

    connect(web: string): Observable<boolean> {
        const serverUrl: string | undefined =
            web === 'admin'
                ? this._apiAdmin.getSocket()
                : this._apiEss.getSocket();

        this.stompHeaders = {
            Authorization:
                'Bearer ' + this._authService.getSession().accessToken,
            transports: ['websocket'],
        };

        const socket = new SockJS(serverUrl);

        this.stompClient = over(socket);
        this.stompClient.debug = null;

        // this.stompClient.debug = (msg: string) => {
        //     console.log(msg);
        // };

        const attemptReconnect = () => {
            this.reconnectAttempts++;

            if (this.reconnectAttempts <= this.maxReconnectAttempts) {
                setTimeout(() => {
                    this.connect(web);
                }, 3000);
            } else {
                console.log(
                    'Max reconnect attempts reached. Stopping reconnection.'
                );
            }
        };

        this.stompClient.connect(
            this.stompHeaders,
            () => {
                this.connectionStatus$.next(true);
                this.isConnected = true;
                this.reconnectAttempts = 0;
            },
            (error: any) => {
                this.connectionStatus$.next(false);
                this.isConnected = false;
                this.disconnect();

                if (this.reconnectAttempts < this.maxReconnectAttempts) {
                    attemptReconnect();
                }
            }
        );

        return this.connectionStatus$.asObservable();
    }

    subscribe(topic: string): Observable<any> {
        return new Observable((observer) => {
            if (!this.stompClient) {
                observer.error('WebSocket connection is not established.');
            }

            let subscription = this.stompClient.subscriptions[topic];
            if (!subscription) {
                subscription = this.stompClient.subscribe(
                    topic,
                    (message: any) => {
                        observer.next(message);
                    }
                );
                this.stompClient.subscriptions[topic] = subscription;
            }

            this.connectionStatus$.subscribe((connected) => {
                if (!connected && subscription) {
                    subscription.unsubscribe();
                    delete this.stompClient.subscriptions[topic];
                }
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                    delete this.stompClient.subscriptions[topic];
                }
            };
        });
    }

    send(destination: string, body: any): void {
        this.stompClient.send(
            destination,
            this.stompHeaders,
            JSON.stringify(body)
        );
    }

    disconnectByTopic(topic: string) {
        if (this.stompClient && this.stompClient.subscriptions[topic]) {
            const subscription = this.stompClient.subscriptions[topic];
            subscription.unsubscribe();
            delete this.stompClient.subscriptions[topic];
        }
    }

    disconnect(): void {
        if (this.stompClient && this.stompClient.connected) {
            Object.keys(this.stompClient.subscriptions).forEach((topic) => {
                delete this.stompClient.subscriptions[topic];
            });

            this.stompClient.disconnect(() => {
                this.connectionStatus$.next(false);
                this.stompClient = null;
            });
        }
    }
}
